export enum DiscountTypes {
  DISCOUNT_NONE = 0,
  DISCOUNT_COUPON = 1,
  DISCOUNT_COUPON_CASH = 2,
  DISCOUNT_CASH = 3,
  DISCOUNT_8PER = 11,
  DISCOUNT_10PER = 12,
  DISCOUNT_COMMON = 13,
  DISCOUNT_PER = 21,
  DISCOUNT_YEN = 22,
}

export enum TypeNames {
  'cash' = '現金',
  'card' = 'クレジットカード',
  'e-money' = '電子マネー',
}

export const DiscountTypeNames = {
  0: '割引なし',
  1: 'クーポン(％）',
  2: 'クーポン(円）',
  3: '値引き(円）',
}

export const DiscountTypeNames1 = {
  0: '割引なし',
  11: '8％対象',
  12: '10％対象',
  13: '共通',
}

export const DiscountTypeNames2 = {
  21: '％',
  22: '円',
}
